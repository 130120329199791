<h2>Order Overview</h2>
<div class="alert alert-primary" role="alert">
  When you place the order you'll have to send money to <b>NL25RABO0123456789</b>. A website administrator will put your order on paid whenever they've received the money.
</div>
<div *ngIf="shoppingCartData" class="row">
  <div class="col-md-3 col-sm-12">
    <app-order-summary [productsInShoppingCart]="shoppingCartData" [makeOrderFromCart]="makeOrderFromCart"></app-order-summary>
  </div>
  <div class="col-md-9 col-sm-12 card shadow-sm p-3 rounded" >
      <div class="card-body">
    <div class="row font-weight-bold" >
        <div class="col-6">
          Product
        </div>
        <div class="col-3">
          Amount
        </div>
        <div class="col-3">
          Price
        </div>
    </div>
    <div>
        <hr>
      <app-order-item *ngFor="let item of shoppingCartData;"[orderItem]="item"></app-order-item>
    </div>
  </div>
  </div>
</div>