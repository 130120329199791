<div class="row cart-item-row align-items-center">
  <div class="body">
    <h5 class="title">Order details:</h5>
    <p>Total of orders: € {{ calculteCost() }}</p>
    <p>Shipping cost: € 0</p>
    <div class="button-box">
      <button type="button" class="btn btn-primary" (click)="makeOrder()">Make order</button> &nbsp;
      <button type="button" class="btn btn-danger" (click)="emptyCart()">Empty Cart</button>
    </div>
  </div>
</div>
