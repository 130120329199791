
<div class="relative">
  <app-header></app-header>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>