<app-spinner *ngIf="isLoading"></app-spinner>
<div class="row" *ngIf="!isLoading">
  <div class="col-md-7">
    <img 
      [src]="product.imagePath" 
      alt="{{product.name}}"
      class="img-responsive">
  </div>
  <div class="col-md-4">
    <div class="container">
      <span> {{ product.name }}</span>
      <p class="inline-price"> €{{product.price}} </p>
      <div class="price-info">
        <i> Prijzen incl. BTW exclusief verpakkings- en verzendkosten</i>
      </div>
      <div class="shipping_info">
        <span class="dot"></span>
        <p class="inline">Uit voorraad leverbaar</p>
        <div class="time-frame">
          <p>Bezorging pakket circa {{deliveryStart | date: 'dd/MM/yyyy'}} - {{deliveryEnd | date: 'dd/MM/yyyy'}}</p>
        </div>
        <button 
          (click)="onAddToCart()"
          style="cursor: pointer;"
          type="button" 
          class="btn btn-success"
          >In mijn winkelmandje
        </button>
      </div>
    </div>
  </div>
</div>

