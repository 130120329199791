<div class="row cart-item-row align-items-center">
  <div id="image-col" class="col-sm-3">
    <img src="{{shoppingCartItem.product.imagePath}}" alt="">
  </div>
  <div class="col-sm-4">
    <h3>{{shoppingCartItem.product.name}}</h3>
    <h5> {{shoppingCartItem.product.description}}</h5>
  </div>
  <div class="col-sm-3">
    <span class="decrement-product">
      <svg (click)="updateAmount(shoppingCartItem.amount - 1)" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
      </svg>
    </span>
    <span class="product-count">
      {{ shoppingCartItem.amount }}
    </span>
    <span class="increment-product">
      <svg (click)="updateAmount(shoppingCartItem.amount + 1)" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
    </span>
  </div>
  <div class="col-sm-2">
    {{ shoppingCartItem.amount * shoppingCartItem.product.price | currency: 'EUR'}}
  </div>
</div>