<div class="col-md-auto">
  <div class="left" (click)="onAddToCart()">
    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" class="bi bi-cart-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
      <path fill-rule="evenodd" d="M8.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 .5-.5z"/>
    </svg>
  </div>
  <a
    style="cursor: pointer;"
    [routerLink]="[product.id]"
    routerLinkActive="active"
    class="list-group-item clearfix">
    <span class="pull-right">
      <img 
        [src]="product.imagePath"
        alt="{{ product.name }}" 
        class="img-responsive">
    </span>
    <div class="pull-left">
      <h5 class="list-group-item-text">€ {{product.price}}</h5>
      <h4 class="list-group-item-heading">{{ product.name }} / <p>{{ product.description }}</p></h4>
    </div>
  </a>
</div>
