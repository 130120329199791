<div class="row">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="col-xl">
    <form (submit)="onSaveProduct(productForm)" #productForm="ngForm" *ngIf="!isLoading">
      <div class="row">
        <div class="col-xl">
          <div class="form-group">
            <label for="vinylFigureId">Vinylfiguur Nummer</label>
            <input
              type="number"
              id="vinyFigureId"
              name="vinylFigureId"
              [ngModel]="product?.vinylFigureId"
              required
              placeholder="Product vinyFigureId"
              #vinylFigureId="ngModel"
              class="form-control">
              <span class="help-block" *ngIf="!vinylFigureId.valid && vinylFigureId.touched">
                Please enter a valid vinylFigureId {{vinylFigureId.value}} is not a valid vinylFigureId
              </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl">
          <div class="form-group">
            <label for="name">Naam</label>
            <input
              type="text"
              name="name"
              [ngModel]="product?.name"
              required
              placeholder="Product name"
              #name="ngModel"
              class="form-control">
              <span class="help-block" *ngIf="!name.valid && name.touched">
                Please enter a valid product name {{name.value}} is not a valid product name
              </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl">
          <div class="form-group">
            <label for="price">Prijs</label>
            <input
              type="number"
              name="price"
              [ngModel]="product?.price"
              required
              placeholder="Product price"
              #price="ngModel"
              class="form-control">
              <span class="help-block" *ngIf="!price.valid && price.touched">
                Please enter a valid price {{price.value}} is not a valid price 
              </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl">
          <div class="form-group">
            <label for="description">Beschrijving</label>
            <input
              type="text"
              name="description"
              [ngModel]="product?.description"
              required
              placeholder="Product description"
              #description="ngModel"
              class="form-control">
              <span class="help-block" *ngIf="!description.valid && description.touched">
                Please enter a valid description {{description.value}} is not a valid description 
              </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl">
          <div class="form-group">
            <label for="imagePath">Image URL</label>
            <input
              type="text"
              id="imagePath"
              name="imagePath"
              [ngModel]="product?.imagePath"
              required
              placeholder="Product imagePath"
              #imagePath="ngModel"
              class="form-control">
              <span class="help-block" *ngIf="!imagePath.valid && imagePath.touched">
                Please enter a valid imagePath {{imagePath.value}} is not a valid imagePath 
              </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container">
          <img [src]="imagePath.value" class="img-responsive">
        </div>
      </div>
      <div class="row">
        <div class="col-xl">
          <button 
            type="submit" 
            class="btn btn-success"
            [disabled]="!productForm.valid">Save</button>
          <button 
            type="button" 
            class="btn btn-danger"
            (click)="onCancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>
