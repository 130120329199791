<div class="row cart-item-row align-items-center">
  <div class="col-sm-3">
    <img src="{{item.imagePath}}" alt="">
  </div>
  <div class="col-sm-5">
    <h3>{{item.name}}</h3>
    <h5> {{item.description}}</h5>
  </div>
  <div class="col-sm-2">
    <span class="product-count">
      {{ item.price}}
    </span>
  </div>
  <div class="col-sm-2">
    {{ item.price * item.amount}} 
  </div>
</div>