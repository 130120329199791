<div>
  <div class="form-group">
    <input type="text" class="form-control" placeholder="Waar ben je naar opzoek?" [(ngModel)]="term">
  </div>
  <hr>
  <div class="row justify-content-md-center">
    <app-product-item
      *ngFor="let productElement of products | filter:term; let i = index"
      [product]="productElement"
      [index]="i">
    </app-product-item>
  </div>
</div>
