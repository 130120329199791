<div>
  <div class="form-group">
    <input type="text" class="form-control" placeholder="Waar ben je naar opzoek?" [(ngModel)]="term">
  </div>
  <hr>
  <div class="button-box">
    <a *ngIf="userIsAuthenticated"
      class="btn btn-primary"
      [routerLink]="['../new']"
      >New Product
    </a>
  </div> 
  <div class="row">
    <div class="col-xl">
      <app-product-management-item 
        *ngFor="let productElement of products | filter:term; let i = index"
        [product]="productElement"
        [productId]="i">
      </app-product-management-item>
    </div>
  </div>
</div>

