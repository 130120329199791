<div class="row">
  <div class="col-md">
    <span class="icon-shoppingCart">
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-cart-check" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
        <path fill-rule="evenodd" d="M11.354 5.646a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708 0z"/>
      </svg>
    </span>
    <span class="align-middle">
      Winkelmandje
    </span>
    <span class="product-count">
      ( {{calculateItemCount()}}
    </span>
    <span class="product-count">
      artikelen )
    </span>
  </div>
</div>
<div class="row">
  <div class="col-md">
    <span class="green-text">
      Bezorging pakket circa {{deliveryStart | date: 'dd/MM/yyyy'}} - {{deliveryEnd | date: 'dd/MM/yyyy'}}
    </span>
  </div>
</div>
<div class="row" *ngIf="productsInCart.length > 0; else noProductsInCart">
  <div class="col-sm-12 col-md-9 col-lg-9">
    <app-shopping-cart-list-item *ngFor="let product of productsInCart;"
                                  [shoppingCartItem]="product"
                                  (updated)="onCartItemUpdated($event)"></app-shopping-cart-list-item>

  </div>
  <div id="order-detail" class="col-sm-12 col-md-3 col-lg-3">
    <app-shopping-cart-order  [shoppingCartItems]="productsInCart"
                              [clearCart]="clearCart"></app-shopping-cart-order>
  </div>
</div>

<ng-template #noProductsInCart>
  <div class="row">
    <div class="col-md">
      <strong>
        No products in cart
      </strong>
    </div>
  </div>
</ng-template>